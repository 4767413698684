import React from 'react';
import BreadcrumbSchema from '../../components/BreadcrumbSchema';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { FaSearch } from 'react-icons/fa';
import ServiceTemplate from '../../components/service/ServiceTemplate';

const WebNew = ({ data }) => {
  const seoData = {
    title: 'SEO対策｜奈良のホームページ制作なら｜インヴォルブ',
    description:
      '検索エンジン最適化（SEO）により、より多くのユーザーにサイトを届けます。キーワード選定、内部施策、コンテンツ最適化を通じて検索順位を向上させ、集客力を強化します。',
    pageUrl: 'https://www.involve-in.jp/service/seo',
    ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'サービス一覧', url: 'https://www.involve-in.jp/service/' },
    { name: 'SEO対策', url: seoData.pageUrl },
  ];

  const works = data.allContentfulCaseStudy.nodes; // Contentful から施工事例を取得

  return (
    <>
      {/* ✅ 構造化パンくずデータを渡す */}
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <ServiceTemplate
        title="SEO対策"
        subtitle="検索順位を上げる"
        icon={FaSearch}
        description="検索エンジン最適化（SEO）により、より多くのユーザーにサイトを届けます。キーワード選定、内部施策、コンテンツ最適化を通じて検索順位を向上させ、集客力を強化します。"
        seo={{
          title: 'SEO対策｜奈良のホームページ制作なら｜インヴォルブ',
          description:
            '検索エンジン最適化（SEO）により、より多くのユーザーにサイトを届けます。キーワード選定、内部施策、コンテンツ最適化を通じて検索順位を向上させ、集客力を強化します。',
          pageUrl: 'https://www.involve-in.jp/service/seo',
          ogImage: 'https://www.involve-in.jp/images/common/ogp.png',
        }}
        breadcrumbs={[
          { name: 'ホーム', url: 'https://www.involve-in.jp/' },
          {
            name: 'サービス一覧',
            url: 'https://www.involve-in.jp/service/',
          },
          {
            name: 'SEO対策',
            url: 'https://www.involve-in.jp/service/seo',
          },
        ]}
        benefits={[
          {
            title: 'キーワード最適化',
            description:
              'ターゲットキーワードを徹底調査し、効果的な配置を行うことで検索上位を狙う施策を実施。',
          },
          {
            title: 'コンテンツSEO',
            description:
              '価値ある記事やランディングページを企画・作成し、継続的な更新をサポート。自然検索流入を増やし、サイトの集客力を強化。',
          },
          {
            title: '技術的SEO',
            description:
              'サイトの速度改善、モバイル対応、構造化データの最適化など、内部施策を徹底し、検索エンジンの評価を向上。',
          },
        ]}
        flow={[
          {
            title: 'サイト分析',
            duration: '1週間',
            description:
              '現状のサイト内部構造や検索エンジンでの認識状況を分析し、改善すべきポイントを明確化。',
          },
          {
            title: 'キーワード戦略策定',
            duration: '1週間',
            description:
              'ターゲットに確実に届くよう、狙うべきキーワードを選定し、SEO戦略を策定。',
          },
          {
            title: 'コンテンツ作成・最適化',
            duration: '2週間',
            description:
              '継続的に更新しやすい企画を立案し、ブログ記事やランディングページの制作・改善を実施。',
          },
          {
            title: '内部対策・技術改善',
            duration: '2週間',
            description:
              'サイトの速度最適化、構造化データの実装、内部リンクの最適化など、技術的SEO施策を実施。',
          },
          {
            title: '結果測定・改善',
            duration: '継続',
            description:
              '検索順位や流入データを分析し、さらなる改善策を講じながら、効果的なSEO対策を継続。',
          },
        ]}
        preparation={[
          {
            title: 'ターゲットキーワードの整理',
            description:
              'SEOで狙いたいキーワードや業界用語を明確にし、最適な戦略を立てるための基準を設定。',
          },
          {
            title: '既存コンテンツの見直し',
            description:
              '現在のページや記事の内容を精査し、SEO的に最適化できるポイントを洗い出し改善。',
          },
          {
            title: '競合サイトの分析',
            description:
              '競合サイトのSEO戦略を調査し、自社の強みを活かした効果的な対策を立案。',
          },
          {
            title: '内部施策の確認',
            description:
              'サイトの構造、URL設計、メタタグ、画像の最適化など、検索エンジンが適切に評価できる状態かをチェック。',
          },
        ]}
        works={works.map((work) => ({
          title: work.title,
          image:
            work.image && work.image.length > 0
              ? getImage(work.image[0].gatsbyImageData)
              : null, // 🔥 最初の画像のみ取得
        }))}
        price="¥100,000〜"
        cta="まずは無料相談"
      />
      {/* ✅ 表示用のパンくずは ServiceTemplate で管理 */}
    </>
  );
};

// GraphQL クエリ（Contentful の施工事例を取得）
export const query = graphql`
  query {
    allContentfulCaseStudy(sort: { publishDate: DESC }) {
      nodes {
        title
        slug
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            formats: [AUTO, WEBP]
            placeholder: BLURRED
          )
        }
      }
    }
  }
`;

export default WebNew;
